import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    editSinglePackage: {},
};

export const editSinglePackageSlice = createSlice({
    name: "editSinglePackage",
    initialState,
    reducers: {
        editSinglePackage: (state, action) => {
            return action.payload;
        },
    },
});

export const { editSinglePackage } = editSinglePackageSlice.actions;

export default editSinglePackageSlice.reducer;