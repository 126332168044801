import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    singlePackage: {},
    isLoading: false,
};

export const singlePackageSlice = createSlice({
    name: "singlePackage",
    initialState,
    reducers: {
        setSinglePackage: (state, action) => {
            return action.payload
        },
        setLoading: (state, { payload = false }) => {
            return {
                ...state,
                isLoading: payload
            }
        }
    },
});

export const { setSinglePackage, setLoading } = singlePackageSlice.actions;

export default singlePackageSlice.reducer;