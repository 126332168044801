import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    singleSubscription: {},
    isLoading: false,
};

export const singleSubscriptionSlice = createSlice({
    name: "singleSubscription",
    initialState,
    reducers: {
        setSingleSubscription: (state, action) => {
            return action.payload
        },
        setLoading: (state, { payload = false }) => {
            return {
                ...state,
                isLoading: payload
            }
        }
    },
});

export const { setSingleSubscription, setLoading } = singleSubscriptionSlice.actions;

export default singleSubscriptionSlice.reducer;