import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import userSlice from './users/usersSlice'
import singleUser from './singleUser'
import editSingleUser from './editSingleUser'
import getSettings from './getSettingDetails'
import editTwilioSettings from './editTwilioSetting'
import editStrapieSettings from './editStripeSetting'
import editSystemSettings from './editSystemSetting'
import agentRegistration from './registerAgent'
import deleteUser from './deleteSingleUser'
import userDetails from './dashboardDetail'
import getReportsData from './getReport'
import getCallHistoryData from './getCallHistory'
import downloadReports from './downloadReport'
import getPackageDetails from './getPackageDetails'
import newPackage from './addNewPackage'
import deletePackage from './deletePackage'
import singlePackage from './getSinglePackage'
import editSinglePackage from './editSinglePackage'
import getSubscriptionData from "./getSubscriptionDetails"
import newSubscription from './addNewSubscription'
import singleSubscription from './getSingleSubscription'
import editSingleSubscription from './editSingleSubscription'
import deleteSubscription from './deleteSubscription'
const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        user: userSlice,
        singleUser,
        editSingleUser,
        getSettings,
        editTwilioSettings,
        editStrapieSettings,
        editSystemSettings,
        agentRegistration,
        deleteUser,
        userDetails,
        getReportsData,
        getCallHistoryData,
        downloadReports,
        getPackageDetails,
        newPackage,
        deletePackage,
        singlePackage,
        editSinglePackage,
        getSubscriptionData,
        newSubscription,
        singleSubscription,
        editSingleSubscription,
        deleteSubscription,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
