import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    downloadUserReport: [],
    downloadAgentReport: [],
    isLoading: false,
};

export const downloadReportSlice = createSlice({
    name: "downloadReports",
    initialState,
    reducers: {
        downloadReports: (state, { payload = {} }) => {
            return {
                ...state,
                ...payload
            }
        },
        setLoading: (state, { payload = false }) => {
            return {
                ...state,
                isLoading: payload
            }
        }
    },
});

export const { downloadReports, setLoading } = downloadReportSlice.actions;

export default downloadReportSlice.reducer;