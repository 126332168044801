import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "network/ApiRequest";
import { PACKAGE_ENDPOINT } from "network/EndPoints";

export const getAllPackageDetails = (payload) => {
    return getRequest(PACKAGE_ENDPOINT.PACKAGE + payload)
}

export const addPackageDetails = (payload) => {
    return postRequest(PACKAGE_ENDPOINT.PACKAGE, payload)
}

export const getSinglePackageDetails = (payload) => {
    return getRequest(PACKAGE_ENDPOINT.PACKAGE + '/' + payload.id)
}

export const editSinglePackageDetails = (payload) => {
    return putRequest(PACKAGE_ENDPOINT.PACKAGE + '/' + payload.id, payload)
}

export const deleteSinglePackageDetails = (payload) => {
    return deleteRequest(PACKAGE_ENDPOINT.PACKAGE + '/' + payload.id)
}

export const changePackageStatus = (payload) => {
    return patchRequest(PACKAGE_ENDPOINT.PACKAGE_STATUS + '/' + payload.id, payload)
}