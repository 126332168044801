import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    editStripeSettings: {},
};

export const editStripeSettingsSlice = createSlice({
    name: "users/stripe",
    initialState,
    reducers: {
        editStripeSettings: (state, action) => {
            return action.payload;
        },
    },
});

export const { editStripeSettings } = editStripeSettingsSlice.actions;

export default editStripeSettingsSlice.reducer;