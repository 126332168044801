import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    newSubscription: {},
};

export const addNewSubscriptionSlice = createSlice({
    name: "newSubscription",
    initialState,
    reducers: {
        newSubscription: (state, action) => {
            return action.payload;
        },
    },
});

export const { newSubscription } = addNewSubscriptionSlice.actions;

export default addNewSubscriptionSlice.reducer;