import { createSlice, current } from "@reduxjs/toolkit";
import { fetchSubscriptionStatusAction } from "store/subscriptionStatus/action";

export const initialState = {
    subscriptionDetails: [],
    totalSubscription: 0,
    isLoading: false,
};

export const getSubscriptionSlice = createSlice({
    name: "subscription",
    initialState,
    reducers: {
        getSubscriptionData: (state, action) => {
            state.subscriptionDetails = action.payload.data;
            state.totalSubscription = action.payload.totalSubscription;
        },
        setLoading: (state, { payload = false }) => {
            return {
                ...state,
                isLoading: payload
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSubscriptionStatusAction.fulfilled, (state, { payload = {} }) => {

            const currentState = current(state);
            const allSubscription = [...currentState?.subscriptionDetails];
            const selectedSubscriptionIndex = allSubscription?.findIndex?.(item => item.id === payload.id);
            allSubscription[selectedSubscriptionIndex] = { ...payload };

            return {
                ...state,
                subscriptionDetails: [...allSubscription]
            };
        });
    }
});

export const { getSubscriptionData, setLoading } = getSubscriptionSlice.actions;

export default getSubscriptionSlice.reducer;