import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    userDeatils: [],
    isLoading: true
};

export const userDeatilsSlice = createSlice({
    name: "dashboard/userDeatils",
    initialState,
    reducers: {
        setUserDeatil: (state, action) => {
            return action.payload;
        },
        setLoading: (state, { payload = false }) => {
            return {
                ...state,
                isLoading: payload
            }
        }
    },
});

export const { setUserDeatil, setLoading } = userDeatilsSlice.actions;

export default userDeatilsSlice.reducer;