import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    getCallHistoryData: [],
    isLoading: false
};

export const getCallHistorySlice = createSlice({
    name: "users/call-history",
    initialState,
    reducers: {
        getCallHistoryData: (state, action) => {
            return {
                getCallHistoryData: [...action.payload] || []
            }
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    },
});

export const { getCallHistoryData, setLoading } = getCallHistorySlice.actions;

export default getCallHistorySlice.reducer;