import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    editTwilioSettings: {},
};

export const editTwilioSettingsSlice = createSlice({
    name: "users/twilio",
    initialState,
    reducers: {
        editTwilioSettings: (state, action) => {
            return action.payload;
        },
    },
});

export const { editTwilioSettings } = editTwilioSettingsSlice.actions;

export default editTwilioSettingsSlice.reducer;