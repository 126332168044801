import { ADMIN_ENDPOINT } from "network/EndPoints";
import { postRequest } from "network/ApiRequest";

export const loginApi = (payload) => {
    return postRequest(ADMIN_ENDPOINT.LOGIN, payload);
};

export const sendOtp = (payload) => {
    return postRequest(ADMIN_ENDPOINT.SEND_OTP, payload)
}

export const verifyOtp = (payload) => {
    return postRequest(ADMIN_ENDPOINT.VERIFY_OTP, payload)
}

export const forgotPassword = (payload) => {
    return postRequest(ADMIN_ENDPOINT.FORGOT_PASSWORD, payload)
}