import { ACCOUNT_ENDPOINT, AGENT_ENDPOINT, COMMON_ENDPOINT } from "network/EndPoints";
import {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
} from "network/ApiRequest";

export const getUsers = (role, query) => {
    return getRequest(COMMON_ENDPOINT.ALL_MEMBERS + '/' + role + query)
}

export const getSingleUser = ({ role, id }) => {
    return getRequest(COMMON_ENDPOINT.SINGLE_MEMBER + '/' + role + "/" + id)
}

export const editSingleUser = (payload) => {
    return putRequest(COMMON_ENDPOINT.SINGLE_MEMBER + '/' + payload.id, payload)
}

export const statusChange = (payload) => {
    return putRequest(ACCOUNT_ENDPOINT.GET_STATUS + '/' + payload.id, payload)
}

export const deleteSingleUser = (payload) => {
    return deleteRequest(COMMON_ENDPOINT.SINGLE_MEMBER + '/' + payload.id)
}

export const registerAgent = (payload) => {
    return postRequest(AGENT_ENDPOINT.REGISTER_AGENT, payload)
}

export const getCallHistory = (payload) => {
    return getRequest(COMMON_ENDPOINT.GET_CALL_HISTORY + '/' + payload.role + '/' + payload.id)
}
