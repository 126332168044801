import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "network/ApiRequest";
import { SUBSCRIPTION_ENDPOINT } from "network/EndPoints";


export const getAllSubscriptionDetails = (payload) => {
    return getRequest(SUBSCRIPTION_ENDPOINT.SUBSCRIPTION + payload)
}

export const addSubscriptionDetails = (payload) => {
    return postRequest(SUBSCRIPTION_ENDPOINT.SUBSCRIPTION, payload)
}

export const getSingleSubscriptionDetails = (payload) => {
    return getRequest(SUBSCRIPTION_ENDPOINT.SUBSCRIPTION + '/' + payload.id)
}

export const editSingleSubscriptionDetails = (payload) => {
    return putRequest(SUBSCRIPTION_ENDPOINT.SUBSCRIPTION + '/' + payload.id, payload)
}

export const deleteSingleSubscriptionDetails = (payload) => {
    return deleteRequest(SUBSCRIPTION_ENDPOINT.SUBSCRIPTION + '/' + payload.id)
}

export const changeSubscriptionStatus = (payload) => {
    return patchRequest(SUBSCRIPTION_ENDPOINT.SUBSCRIPTION_STATUS + '/' + payload.id, payload)
}