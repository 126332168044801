import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    editSingleSubscription: {},
};

export const editSingleSubscriptionSlice = createSlice({
    name: "editSingleSubscription",
    initialState,
    reducers: {
        editSingleSubscription: (state, action) => {
            return action.payload;
        },
    },
});

export const { editSingleSubscription } = editSingleSubscriptionSlice.actions;

export default editSingleSubscriptionSlice.reducer;