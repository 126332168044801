import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    deleteUser: {},
};

export const deleteUserSlice = createSlice({
    name: "users/stripe",
    initialState,
    reducers: {
        deleteUser: (state, action) => {
            return action.payload;
        },
    },
});

export const { deleteUser } = deleteUserSlice.actions;

export default deleteUserSlice.reducer;