import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    editSingleUser: {},
};

export const editSingleUserSlice = createSlice({
    name: "users/editSingleUser",
    initialState,
    reducers: {
        editSingleUser: (state, action) => {
            return action.payload;
        },
    },
});

export const { editSingleUser } = editSingleUserSlice.actions;

export default editSingleUserSlice.reducer;