/* eslint-disable no-undef */
import axios from "axios";
import store from "../store";
import { PERSIST_STORE_NAME } from "../constants/app.constant";
import deepParseJson from "utils/deepParseJson";
import appConfig from "configs/app.config";
import { onSignOutSuccess } from "store/auth/sessionSlice";

export const axiosClient = axios.create({
    baseURL: appConfig.baseURL,
    headers: {
        Accept: "application/json",
    },
});

axiosClient.interceptors.request.use(
    (config) => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
        const persistData = deepParseJson(rawPersistData);

        const hasAccessToken = persistData?.auth?.session.token;
        hasAccessToken &&
            (config.headers.Authorization = `Bearer ${hasAccessToken}`);
        if (config.data instanceof FormData) {
            config.headers.ContentType = config.data.getHeaders();
        } else {
            config.headers.ContentType = "application/json";
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
    { synchronous: true }
);

axiosClient.interceptors.response.use(function (res) {
    return res;
});

axiosClient.interceptors.response.use(
    (response) => {
        if (response?.data?.type === "application/pdf") {
            return response.data;
        } else if (response.data) {
            return { ...response.data };
        }
        return response;
    },
    (error) => {

        switch (error?.response?.status) {
            case 401:
            case 403:
                unauthorizeAccess();
                return Promise.reject(error.response.data);
            case 400:
            case 404:
            case 405:
            case 501:
            case 422:
                return Promise.reject(error.response.data);
            default:
                return Promise.reject(error);
        }
    }
);

function getUrl(config) {
    if (config?.baseURL) {
        let _url = config?.url;
        return _url.replace(config?.baseURL, "");
    }
    return config?.url;
}

const unauthorizeAccess = () => {
    store.dispatch(onSignOutSuccess());
};
