import { Notification, toast } from "components/ui";
export const triggerMessage = (type, msg, placement = "top-end", setToast = () => { }, timing = 3000) => {

    const reset = () => {
        setToast(true)
    }

    toast.push(
        <Notification type={type} duration={timing}>
            {msg}
        </Notification>,
        {
            placement: placement,
        },
        setToast && setToast(false),
        setTimeout(reset, 3000)
    );
};

export const FormatNumberToCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    return formatter.format(number);
};

