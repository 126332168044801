import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    getSettings: {},
    isLoading: false
};

export const getSettingsSlice = createSlice({
    name: "users/configuration",
    initialState,
    reducers: {
        getSettings: (state, action) => {
            return action.payload;
        },
        setLoading: (state, { payload = false }) => {
            return {
                ...state,
                isLoading: payload
            }
        }
    },
});

export const { getSettings, setLoading } = getSettingsSlice.actions;

export default getSettingsSlice.reducer;