import { createSlice, current } from "@reduxjs/toolkit";
import { fetchStatusAction } from "store/status/action";

export const initialState = {
    user: [],
    agent: [],
    totalUsers: 0,
    isLoading: false
};

export const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setUsers: (state, { payload = {} }) => {
            return {
                ...state,
                ...payload
            }
        },
        setLoading: (state, { payload = false }) => {
            return {
                ...state,
                isLoading: payload
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchStatusAction.fulfilled, (state, { payload = {} }) => {
            const isRegular = payload?.role_id == 1
            const currentState = current(state)
            let allUsers = isRegular ? currentState.user?.slice?.() : currentState.agent?.slice?.()
            const selectedUserIndex = allUsers?.findIndex?.(item => item['_id'] === payload["_id"])
            allUsers[selectedUserIndex] = { ...payload }
            return {
                ...state,
                [isRegular ? 'user' : 'agent']: allUsers
            }
        });
    }
});

export const { setUsers, setLoading } = usersSlice.actions;

export default usersSlice.reducer;