import appConfig from "configs/app.config";
export const ENDPOINT = {
    LOGIN: `${appConfig.apiPrefix}/login`,
    REGISTER: `${appConfig.apiPrefix}/registration`,

};

export const ADMIN_ENDPOINT = {
    LOGIN: `${appConfig.apiPrefix}${appConfig.adminPrefix}/login`,
    DASHBOARD: `${appConfig.apiPrefix}${appConfig.adminPrefix}/dashboard`,
    UPDATE_PASSWORD: `${appConfig.apiPrefix}${appConfig.userPrefix}/change-password`,
    SEND_OTP: `${appConfig.apiPrefix}/send-otp/forgot-password`,
    VERIFY_OTP: `${appConfig.apiPrefix}/verify-otp/forgot-password`,
    FORGOT_PASSWORD: `${appConfig.apiPrefix}/forgot-password`
}

export const COMMON_ENDPOINT = {
    ALL_MEMBERS: `${appConfig.apiPrefix}${appConfig.adminPrefix}/users`,
    SINGLE_MEMBER: `${appConfig.apiPrefix}${appConfig.adminPrefix}/user`,
    FILTER_DATA: `${appConfig.apiPrefix}${appConfig.adminPrefix}/filter`,
    GET_CALL_HISTORY: `${appConfig.apiPrefix}${appConfig.adminPrefix}/call-history`

}

export const ACCOUNT_ENDPOINT = {
    GET_STATUS: `${appConfig.apiPrefix}${appConfig.adminPrefix}/account-status`

}

export const AGENT_ENDPOINT = {
    REGISTER_AGENT: `${appConfig.apiPrefix}${appConfig.agentPrefix}/register`
}

export const REPORT_ENDPOINT = {
    GET_REPORTS: `${appConfig.apiPrefix}${appConfig.adminPrefix}/users-report`,
    DOWNLOAD_REPORT: `${appConfig.apiPrefix}${appConfig.adminPrefix}/download-users-reports`
}

export const SETTING_ENDPOINT = {
    GET_CONFIGURATION: `${appConfig.apiPrefix}${appConfig.adminPrefix}/setting`,
    EDIT_TWILIO_SETTING: `${appConfig.apiPrefix}${appConfig.adminPrefix}/twilio-setting`,
    EDIT_SYSTEM_SETTING: `${appConfig.apiPrefix}${appConfig.adminPrefix}/common-setting`,
    EDIT_STRIPE_SETTING: `${appConfig.apiPrefix}${appConfig.adminPrefix}/stripe-setting`
}

export const USER_ENDPOINT = {
    SIGNUP: `${appConfig.apiPrefix}/signup`,
    INVOICE: `${appConfig.apiPrefix}/invoice`,
};

export const PACKAGE_ENDPOINT = {
    PACKAGE: `${appConfig.apiPrefix}/package`,
    PACKAGE_STATUS: `${appConfig.apiPrefix}/package/status`
}

export const SUBSCRIPTION_ENDPOINT = {
    SUBSCRIPTION: `${appConfig.apiPrefix}/subscription`,
    SUBSCRIPTION_STATUS: `${appConfig.apiPrefix}/subscription/status`
}