const appConfig = {
    baseURL: "https://api.gabpals.com/",
    apiPrefix: "v1",
    agentPrefix: "/agent",
    adminPrefix: "/admin",
    userPrefix: "/user",
    authenticatedEntryPath: "/dashboard",
    unAuthenticatedEntryPath: "/sign-in",
};

export default appConfig;
