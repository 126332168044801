import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import {
	// apiSignIn,
	// apiSignOut,
	apiSignUp
} from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { loginApi } from "api/auth";
import { triggerMessage } from "utils/general";
// import { useState } from "react";

function useAuth() {
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const query = useQuery();

	const { token, signedIn } = useSelector((state) => state.auth.session);

	const signIn = async (values) => {
		try {
			const resp = await loginApi(values);
			if (resp) {
				const access_token = resp.access_token;
				dispatch(onSignInSuccess(access_token));
				if (resp.user_details) {
					dispatch(
						setUser(
							resp.user_details || {
								avatar: "",
								name: "Anonymous",
								email: "",
							}
						)
					);
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY);
				navigate(
					redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
				);
				return {
					status: "success",
					message: "",
				};
			}
		} catch (errors) {
			triggerMessage("danger", errors?.message)
			return errors
			// return {
			//     status: "failed",
			//     message: errors?.message || errors.toString(),
			// };
		}
	};

	const signUp = async (values) => {
		try {
			const resp = await apiSignUp(values);
			if (resp) {
				const access_token = resp.access_token;
				dispatch(onSignInSuccess(access_token));
				if (resp.user_details) {
					dispatch(
						setUser(
							resp.user_details || {
								avatar: "",
								userName: "Anonymous",
								authority: ["USER"],
								email: "",
							}
						)
					);
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY);
				navigate(
					redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
				);
				return {
					status: "success",
					message: "",
				};
			}
		} catch (errors) {
			return {
				status: "failed",
				message: errors?.response?.data?.message || errors.toString(),
			};
		}
	};

	const handleSignOut = () => {
		dispatch(onSignOutSuccess());
		dispatch(setUser(initialState));
		navigate(appConfig.unAuthenticatedEntryPath);
	};

	const signOut = async () => {
		// await apiSignOut();
		handleSignOut();
	};

	return {
		authenticated: token && signedIn,
		signIn,
		signUp,
		signOut,
	};
}

export default useAuth;
