import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    editSystemSettings: {},
};

export const editSystemSettingsSlice = createSlice({
    name: "users/system",
    initialState,
    reducers: {
        editSystemSettings: (state, action) => {
            return action.payload;
        },
    },
});

export const { editSystemSettings } = editSystemSettingsSlice.actions;

export default editSystemSettingsSlice.reducer;