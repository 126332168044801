import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    deleteSubscription: {},
};

export const deleteSubscriptionSlice = createSlice({
    name: "deleteSubscription",
    initialState,
    reducers: {
        deleteSubscription: (state, action) => {
            return action.payload;
        },
    },
});

export const { deleteSubscription } = deleteSubscriptionSlice.actions;

export default deleteSubscriptionSlice.reducer;