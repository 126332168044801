import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    getUserReportsData: [],
    getAgentReportData: [],
    totalUserReport: 0,
    totalAgentReport: 0,
    isLoading: false,
};

export const getReportSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {
        getReportsData: (state, { payload = {} }) => {
            return {
                ...state,
                ...payload
            }
        },
        setLoading: (state, { payload = false }) => {
            return {
                ...state,
                isLoading: payload
            }
        }
    },
});

export const { getReportsData, setLoading } = getReportSlice.actions;

export default getReportSlice.reducer;