import { createAsyncThunk } from "@reduxjs/toolkit";
import { changePackageStatus } from "api/package";
import { triggerMessage } from "utils/general";

export const fetchPackageStatusAction = createAsyncThunk(
    "account/fetchPackageStatusAction",
    async (payload, thunkAPI) => {
        try {
            const response = await changePackageStatus({ id: payload.id, status: payload.status })
            if (response?.success) {
                triggerMessage("success", response?.message)
                return response?.data
            }
            return {}
        } catch (error) {
            triggerMessage("danger", error?.message)
        }
    }
);