import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    agentRegistration: {},
};

export const registerAgentSlice = createSlice({
    name: "agent/registration",
    initialState,
    reducers: {
        agentRegistration: (state, action) => {
            return action.payload;
        },
    },
});

export const { agentRegistration } = registerAgentSlice.actions;

export default registerAgentSlice.reducer;