import { createSlice, current } from "@reduxjs/toolkit";
import { fetchPackageStatusAction } from "store/packageStatus/action";

export const initialState = {
    packageDetails: [],
    totalPackages: 0,
    isLoading: false,
};

export const getPackageSlice = createSlice({
    name: "packages",
    initialState,
    reducers: {
        getPackageData: (state, action) => {
            state.packageDetails = action.payload.data;
            state.totalPackages = action.payload.totalPackages;
        },
        setLoading: (state, { payload = false }) => {
            return {
                ...state,
                isLoading: payload
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPackageStatusAction.fulfilled, (state, { payload = {} }) => {

            const currentState = current(state);
            const allPackage = [...currentState?.packageDetails];
            const selectedPackageIndex = allPackage?.findIndex?.(item => item.id === payload.id);
            allPackage[selectedPackageIndex] = { ...payload };

            return {
                ...state,
                packageDetails: [...allPackage]
            };
        });
    }
});

export const { getPackageData, setLoading } = getPackageSlice.actions;

export default getPackageSlice.reducer;