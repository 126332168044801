import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    deletePackage: {},
};

export const deletePackageSlice = createSlice({
    name: "deletePackage",
    initialState,
    reducers: {
        deletePackage: (state, action) => {
            return action.payload;
        },
    },
});

export const { deletePackage } = deletePackageSlice.actions;

export default deletePackageSlice.reducer;