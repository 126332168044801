import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    newPackage: {},
};

export const addNewPackageSlice = createSlice({
    name: "newPackage",
    initialState,
    reducers: {
        newPackage: (state, action) => {
            return action.payload;
        },
    },
});

export const { newPackage } = addNewPackageSlice.actions;

export default addNewPackageSlice.reducer;